import CartIconOOI from '@wix/pro-gallery-artstore-statics/dist/src/applications/gallery/cartIconOOI';
import '../../styles/dynamic/artStore/artstoreCartIconWixStyles.scss';
import '../../styles/dynamic/artStore/artstorePagesWixStyles.scss';
import '../../styles/static/artStore/artstoreCartIcon.scss';
import '../../styles/static/artStore/artstorePages.scss';
import { withStyles } from '@wix/native-components-infra';

export default {
  component: withStyles(CartIconOOI, {
    cssPath: ['cart.min.css'],
    strictMode: false,
  }),
};
